import { useEffect, useState } from "react";
import Logo from "../Assets/svgs/Logo";
import Button from "../Components/Button";
import { tertiary, btnText1 } from "../Assets/Colors";
import useWindowSize from '../Common/WindowSize';
import { useTranslation } from "react-i18next";
import { GrLanguage } from "react-icons/gr";

export default function Navbar ( props )
{
    const size = useWindowSize();
    const { i18n, t } = useTranslation();
    const [ hoveredItem, setHoveredItem ] = useState( null );
    const [ open, setOpen ] = useState( false );
    const [ openDropdown, setOpenDropdown ] = useState( null );
    const [ lang, setLang ] = useState( i18n.language );

    useEffect( () =>
    {
        const storedLang = localStorage.getItem( "lang" ) || "en";
        i18n.changeLanguage( storedLang );
        setLang( storedLang );
        document.documentElement.dir = storedLang === "ar" ? "rtl" : "ltr";
    }, [ i18n ] );

    const changeLanguage = ( lng ) =>
    {
        i18n.changeLanguage( lng );
        setLang( lng );
        localStorage.setItem( "lang", lng );
        document.documentElement.dir = lng === "ar" ? "rtl" : "ltr";
    };

    const handleToggleDropdown = ( index ) =>
    {
        if ( openDropdown === index )
        {
            setOpenDropdown( null );
        } else
        {
            setOpenDropdown( index );
        }
    };
    const handleMouseEnter = ( index ) =>
    {
        setHoveredItem( index );
    };

    const handleMouseLeave = () =>
    {
        setHoveredItem( null );
    };

    const navItems = {
        [ t( "Product" ) ]: {
            items: [
                { label: t( "Features" ), link: "/features" },
                { label: t( "Inegrations" ), link: "/integrations" },
                { label: t( "Privacy & Security" ), link: "/security" },
                { label: t( "What's new" ), link: "/whats-new" }
            ]
        },
        [ t( "Pricing" ) ]: {
            link: "/pricing",
            items: []
        },
        [ t( "Contact Sales" ) ]: {
            link: "/contact-sales",
            items: []
        }
    };

    return (
        <header class={ `flex sm:flex-wrap lm:flex-wrap tab:flex-wrap bg-black justify-start flex-nowrap z-50 w-full sm:w-[100vw] lm:w-[100vw] tab:w-[100vw] text-sm py-2.5 sm:p-0 lm:p-0 tab:p-0 h-18 fixed ${ open === true ? "bg-white" : props.bg} ${ props.text }` }>
            <nav class="max-w-[85rem] w-full sm:max-w-[100vw] lm:max-w-[100vw] tab:max-w-[100vw] sm:w-[100vw] lm:w-[100vw] tab:w-[100vw] mx-auto px-12 sm:px-0 lm:px-0 tab:px-0" aria-label="Global">
                <div class="relative h-18 flex sm:items-center lm:items-center tab:items-center sm:px-2 lm:px-2 tab:px-2">
                    <div class="flex items-center justify-between w-64 sm:w-[100vw] lm:w-[100vw] tab:w-[100vw] h-12 overflow-hidden">
                        <a class="flex-none text-xl font-semibold" href="/"><Logo fill={ open === true ? "black":props.logoFill } width="10rem" height="12rem" /></a>
                        <div class="hidden sm:block lm:block tab:block">
                            <button type="button" onClick={ () => { setOpen( !open ) } } class="hs-collapse-toggle p-2 inline-flex justify-center items-center gap-x-2 rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none" data-hs-collapse="#navbar-hover-event" aria-controls="navbar-hover-event" aria-label="Toggle navigation">
                                { open ?
                                    <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>
                                    :
                                    <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="3" x2="21" y1="6" y2="6" /><line x1="3" x2="21" y1="12" y2="12" /><line x1="3" x2="21" y1="18" y2="18" /></svg>
                                }
                            </button>
                        </div>
                    </div>
                    <div id="navbar-hover-event" class="sm:hidden lm:hidden tab:hidden hs-collapse overflow-hidden transition-all duration-300 basis-full grow block">
                        <div class="flex sm:flex-col lm:flex-col tab:flex-col sm:gap-y-3 lm:gap-y-3 tab:gap-y-3 gap-y-0 mt-0 flex-row items-center justify-end">
                            { Object.entries( navItems ).map( ( [ key, value ], index ) => (
                                value.items.length > 0 ?
                                    <div class="hs-dropdown [--strategy:static] [--strategy:absolute] [--adaptive:none] [--trigger:hover]" onMouseEnter={ () => handleMouseEnter( index ) }
                                        onMouseLeave={ handleMouseLeave }>
                                        <button
                                            type="button"
                                            className="p-4 flex items-center w-full font-medium relative"
                                        >
                                            { key }
                                            <svg
                                                className={ `ms-2 flex-shrink-0 size-4 transform transition-transform duration-200 ease-in ${ hoveredItem === index ? 'rotate-180' : '' }` }
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                style={ { transform: hoveredItem === index ? 'rotate(360deg)' : 'rotate(180deg)' } }
                                            >
                                                <path d="m6 15 6-6 6 6" />
                                            </svg>
                                        </button>
                                        <div className="hs-dropdown-menu flex transition-[opacity,margin] border duration-[0.1ms] duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 hidden z-50 top-12 start-18 min-w-10 bg-white shadow-md rounded-lg absolute py-2">
                                            { Object.entries( value.items.reduce( ( acc, item ) =>
                                            {
                                                if ( item.heading )
                                                {
                                                    if ( !acc[ item.heading ] )
                                                    {
                                                        acc[ item.heading ] = [];
                                                    }
                                                    acc[ item.heading ].push( item );
                                                } else
                                                {
                                                    if ( !acc[ 'No Heading' ] )
                                                    {
                                                        acc[ 'No Heading' ] = [];
                                                    }
                                                    acc[ 'No Heading' ].push( item );
                                                }
                                                return acc;
                                            }, {} ) ).map( ( [ heading, items ], index ) => (
                                                <div key={ index } className="flex flex-col">
                                                    { heading !== 'No Heading' ? (
                                                        <>
                                                            <h6 className="text-md text-primary font-medium border-b border-gray-300 bg-gray-100 pl-4 pr-12 py-4 w-52 -mt-2">{ heading }</h6>
                                                            <div className="py-2">
                                                                { items.map( ( item, idx ) => (
                                                                    <a key={ idx } className="flex items-center font-medium py-3 px-4 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-primary mx-2" href={ item.link }>
                                                                        { item.label }
                                                                    </a>
                                                                ) ) }
                                                            </div>
                                                        </>
                                                    ) : (
                                                        items.map( ( item, idx ) => (
                                                            <a key={ idx } className="flex items-center py-3 font-medium px-4 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:ring-0 focus:ring-none mx-2" href={ item.link }>
                                                                { item.label }
                                                            </a>
                                                        ) )
                                                    ) }
                                                </div>
                                            ) ) }
                                        </div>
                                    </div> : (
                                        <div className="flex text-sm items-center relative ml-2 mr-2" key={ index }>
                                            <a className="font-medium me-2" href={ value.link } aria-current="page">{ key }</a>
                                        </div>
                                    )
                            ) ) }

                            <div className="flex items-center">
                                { lang === "en" ?
                                    <button
                                        type="button"
                                        className="p-4 flex items-center w-full font-lg relative"
                                        onClick={ () => changeLanguage( "ar" ) }
                                    >
                                        <span className="me-1 text-lg">العربیۃ</span>
                                        <GrLanguage size={ 18 } />
                                    </button> :
                                    <button
                                        type="button"
                                        className="p-4 flex items-center w-full font-medium relative"
                                        onClick={ () => changeLanguage( "en" ) }
                                    >
                                        <span className="me-1 text-md">English</span>
                                        <GrLanguage size={ 18 } />
                                    </button>
                                }
                                <span>
                                    <Button onClick={ () => { window.location.href = "https://spacetwin.sa/"; } } text={ t( "Sign In" ) } width="6rem" height="2.4rem" background={ props.btnbg } color={ props.btntext } />
                                </span>
                                <span className="mx-3">
                                    <Button onClick={ () => { window.location.href = "https://spacetwin.sa/"; } } text={ t( "Sign Up" ) } width="6rem" height="2.4rem" background={ props.btnbg } color={ props.btntext } />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Mobile Nav */ }
                { open && (
                    <div className="hidden sm:p-4 lm:p-4 tab:p-4 sm:block lm:block tab:block bg-white hs-collapse overflow-hidden transition-all duration-300 basis-full grow block">
                        <div className="flex sm:flex-col lm:flex-col tab:flex-col sm:gap-y-3 lm:gap-y-3 tab:gap-y-3 gap-y-0 mt-0 flex-row items-start justify-end">
                            { Object.entries( navItems ).map( ( [ key, value ], index ) => (
                                value.items.length > 0 ? (
                                    <div className="hs-dropdown w-full ms-2 font-semibold text-lg [--strategy:static] [--strategy:absolute] [--adaptive:none] [--trigger:hover]" key={ index }>
                                        <button
                                            type="button"
                                            className="p-2 flex items-center w-full relative"
                                            onClick={ () => handleToggleDropdown( index ) }
                                        >
                                            { key }
                                            <svg
                                                className={ `ms-2 flex-shrink-0 size-4 transform transition-transform duration-200 ease-in ${ openDropdown === index ? 'rotate-180' : '' }` }
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                style={ { transform: openDropdown === index ? 'rotate(360deg)' : 'rotate(180deg)' } }
                                            >
                                                <path d="m6 15 6-6 6 6" />
                                            </svg>
                                        </button>
                                        <div className={ `hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] duration-[150ms] ${ openDropdown === index ? 'opacity-100 block' : 'opacity-0 hidden' } rounded-lg py-2` }>
                                            { Object.entries( value.items.reduce( ( acc, item ) =>
                                            {
                                                if ( item.heading )
                                                {
                                                    if ( !acc[ item.heading ] )
                                                    {
                                                        acc[ item.heading ] = [];
                                                    }
                                                    acc[ item.heading ].push( item );
                                                } else
                                                {
                                                    if ( !acc[ 'No Heading' ] )
                                                    {
                                                        acc[ 'No Heading' ] = [];
                                                    }
                                                    acc[ 'No Heading' ].push( item );
                                                }
                                                return acc;
                                            }, {} ) ).map( ( [ heading, items ], subIndex ) => (
                                                <div key={ subIndex } className="flex flex-col ">
                                                    { heading !== 'No Heading' ? (
                                                        <>
                                                            <h6 className="text-md pl-4 pr-12 py-4 w-full -mt-2">{ heading }</h6>
                                                            <div className="py-2">
                                                                { items.map( ( item, idx ) => (
                                                                    <a key={ idx } className="flex items-center font-medium py-3 px-4 rounded-lg text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-primary mx-2" href={ item.link }>
                                                                        { item.label }
                                                                    </a>
                                                                ) ) }
                                                            </div>
                                                        </>
                                                    ) : (
                                                        items.map( ( item, idx ) => (
                                                            <a key={ idx } className="flex font-medium ms-4 items-center py-3 px-4 rounded-lg text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-primary mx-2" href={ item.link }>
                                                                { item.label }
                                                            </a>
                                                        ) )
                                                    ) }
                                                </div>
                                            ) ) }
                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex items-center relative ml-2 p-2 ms-2" key={ index }>
                                        <a className="font-semibold text-lg" href={ value.link } aria-current="page">{ key }</a>
                                    </div>
                                )
                            ) ) }
                            <div className="flex flex-col w-full">
                                { lang === "en" ?
                                    <button
                                        type="button"
                                        className="p-4 flex items-center w-full font-lg relative"
                                        onClick={ () => changeLanguage( "ar" ) }
                                    >
                                        <GrLanguage size={ 18 }/>
                                        <span className="text-xl ms-2">العربیۃ</span>
                                    </button> :
                                    <button
                                        type="button"
                                        className="p-4 flex items-center w-full font-medium relative"
                                        onClick={ () => changeLanguage( "en" ) }
                                    >
                                        <GrLanguage size={ 18 } />
                                        <span className="text-lg ms-2">English</span>
                                    </button>
                                }
                                <span className="my-2 ">
                                    <Button onClick={ () => { window.location.href = "https://spacetwin.sa/"; } } text={ t( "Sign Up" ) }
                                        width={ size.width > 300 && size.width < 992 ? "100%" : "8rem" }
                                        height={ size.width > 300 && size.width < 992 ? "3rem" : "3rem" }
                                        background={ props.btnbg } color={ props.btntext } />
                                </span>
                                <span className="my-2">
                                    <Button onClick={ () => { window.location.href = "https://spacetwin.sa/"; } } text={ t( "Sign In" ) }
                                        width={ size.width > 300 && size.width < 992 ? "100%" : "6rem" }
                                        height={ size.width > 300 && size.width < 992 ? "3rem" : "3rem" }
                                        background={ tertiary } color={ btnText1 } />
                                </span>
                            </div>
                        </div>
                    </div>
                ) }
            </nav >
        </header >
    )
}