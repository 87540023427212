import React, { useEffect, useState } from 'react';
import Star1 from "../Assets/svgs/Star1";
import Star2 from "../Assets/svgs/Star2";
import Star3 from "../Assets/svgs/Star3";
import Star4 from "../Assets/svgs/Star4";
import Navbar from "../Common/Navbar";
import Footer from '../Common/Footer';
import TrySpaceTwin from '../Components/TrySpaceTwin';
import { primary, lightPrimary } from "../Assets/Colors";
import Button from '../Components/Button';
import SecurityCard from '../Components/Security';
import lock from "../Assets/svgs/Lock.svg"
import msg from "../Assets/svgs/Msg.svg"
import user from "../Assets/svgs/User.svg"
import tsp1 from '../Assets/images/tsp2.png'
import useWindowSize from '../Common/WindowSize';
import { useTranslation } from "react-i18next";

export default function Security ()
{
    const size = useWindowSize();
    const { i18n, t } = useTranslation();
    const [ lang, setLang ] = useState( i18n.language );

    useEffect( () =>
    {
        const storedLang = localStorage.getItem( "lang" ) || "en";
        console.log( "Stored Language:", storedLang );
        i18n.changeLanguage( storedLang );
        setLang( storedLang );
        document.documentElement.dir = storedLang === "ar" ? "rtl" : "ltr";
        console.log( "Document Direction:", document.documentElement.dir );
    }, [ i18n, lang ] );

    const cardData1 = [
        {
            icon: user,
            heading: t("Focus Zone"),
            text: t( "We allow users to navigate to a focus zone (working desk or meeting table) so he/she can focus on working alone or with the working group without being distracted by other." )
        },
        {
            icon: msg,
            heading: t("Whiteboard Sharing"),
            text: t( "Now, users can share whiteboards together to share ideas and concepts faster and enhance the creativity process." )
        },
        {
            icon: lock,
            heading: t("Private Area"),
            text: t( "People who are working on sensitive matters can be isolated in a private area that is not accessible by visitors to ensure information security." )
        },
    ]

    return (
        <div className="w-full">
            <Navbar bg="bg-white" text="text-black" logoFill="#333" btnbg={ primary } btntext="white" />
            <header className="relative mx-auto h-120 sm:h-130 lm:h-130 bg-black flex flex-col items-center justify-center">
                <div className="absolute inset-0">
                    <div className="absolute top-[22%] left-[18%] w-3 h-3">
                        <Star1 />
                    </div>
                    <div className="absolute top-[48%] left-[4%] w-3 h-3">
                        <Star2 />
                    </div>
                    <div className="absolute top-[54%] left-[8%] w-3 h-3">
                        <Star3 />
                    </div>
                    <div className="absolute top-[40%] right-[3.6%] w-3 h-3">
                        <Star4 />
                    </div>
                </div>
                <div className="relative z-[49] mx-auto max-w-[85rem] w-full px-12 pt-8 sm:p-8 lm:py-16 flex flex-col items-center">
                    <h6 className="text-center text-primary text-6xl sm:text-5xl lm:text-5xl font-semibold ltr:tracking-wider sm:mt-16 lm:mt-24">{t("Security at Space Twin")}</h6>
                    <h6 className="text-center text-xl font-medium leading-8 text-white w-2/3 sm:w-full lm:w-full mx-auto my-6">{t("Create a secure virtual space where your team can confidently hold private conversations, interact with guests, and create a great work environment.")}</h6>
                    <Button text={t("View Trust Center")} width={ size.width > 300 && size.width < 531 ? "100%" : "12rem" }
                        height="3.4rem" background={ lightPrimary } color="white" border={ `0.02rem solid white` } />
                </div>
            </header>
            <section className="relative flex mx-auto p-12 py-0 sm:p-6 lm:p-6 tab:p-6 sl:p-6">
                <div className="relative z-[49] mx-auto max-w-[85rem] w-full px-12 pt-8 sm:p-2 lm:p-2 tab:p-2 sl:p-2">
                    <div className='flex flex-wrap mt-10 justify-between'>
                        { cardData1.map( ( n, index ) => (
                            <div className='w-1/2 my-3 sm:w-full lm:w-full lm:mx-auto'>
                                <SecurityCard icon={ n.icon } heading={ n.heading } text={ n.text } />
                            </div>
                        ) ) }

                    </div>
                    <br />
                    <br />
                    <hr />
                </div>
            </section>
            <TrySpaceTwin text={t("Feel like a team again on Space Twin")} img={ tsp1 } />
            <Footer />
        </div>
    )
}