import { BrowserRouter, Routes, Route } from "react-router-dom";
import Index from "./Pages/Index"
import './App.css';
import "preline/preline";
import Features from "./Pages/Features";
import Integrations from "./Pages/Integrations";
import Security from "./Pages/Security";
import Pricing from "./Pages/Pricing";
import News from "./Pages/News.js";
import Contact from "./Pages/Contact.js";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n.js";

function App() {
  return (
    <I18nextProvider i18n={ i18n }>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <Index /> } />
          <Route path="/features" element={<Features/>}/>
          <Route path="/integrations" element={<Integrations/>}/>
          <Route path="/security" element={<Security/>}/>
          <Route path="/pricing" element={ <Pricing />}/>
          <Route path="/whats-new" element={ <News />}/>
          <Route path="/contact-sales" element={ <Contact />}/>
        </Routes>
      </BrowserRouter>
    </I18nextProvider>
  );
}

export default App;
