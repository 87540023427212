import React, { useEffect, useState } from 'react';
import Star1 from "../Assets/svgs/Star1";
import Star2 from "../Assets/svgs/Star2";
import Star3 from "../Assets/svgs/Star3";
import Star4 from "../Assets/svgs/Star4";
import Navbar from "../Common/Navbar";
import Footer from '../Common/Footer';
import { primary} from "../Assets/Colors";
import Button from '../Components/Button';
import { useTranslation } from "react-i18next";

export default function Pricing ()
{
    const { i18n, t } = useTranslation();
    const [ lang, setLang ] = useState( i18n.language );
    const [ isAnnual, setIsAnnual ] = useState( false );
    const [ basicPrice, setBasicPrice ] = useState( 49 );
    const [ advancePrice, setAdvancePrice ] = useState( 69 );

    const handleToggle = () =>
    {
        if ( isAnnual )
        {
            setBasicPrice( 49 );
            setAdvancePrice(69);
        } else
        {
            setBasicPrice( 49 * 12 );
            setAdvancePrice( 69 * 12 );
        }
        setIsAnnual( !isAnnual );
    };

    useEffect( () =>
    {
        const storedLang = localStorage.getItem( "lang" ) || "en";
        console.log( "Stored Language:", storedLang );
        i18n.changeLanguage( storedLang );
        setLang( storedLang );
        document.documentElement.dir = storedLang === "ar" ? "rtl" : "ltr";
        console.log( "Document Direction:", document.documentElement.dir );
    }, [ i18n, lang ] );

    const free = [
        t( "Public and private chat" ),
        t( "Screen sharing" ),
        t( "Video sharing" ),
        t( "Public and private areas" ),
        t( "Task management" ),
        t( "File management" ),
        t( "Audio sharing" ),
        t( "Sound distance effects" ),
        t( "Visitor invitations" ),
        t( "Language support" ),
        t( "Virtual avatars for employees" ),
        t( "Employee check-in and check-out" ),
        t( "Employee statuses" ),
        t( "Space-specific notifications" )
    ];

    const premium = [
        t( "Public and private chat" ),
        t( "Screen sharing" ),
        t( "Video sharing" ),
        t( "Public and private areas" ),
        t( "Task management" ),
        t( "File management" ),
        t( "Audio sharing" ),
        t( "Sound distance effects" ),
        t( "Visitor invitations" ),
        t( "Language support" ),
        t( "Virtual avatars for employees" ),
        t( "Employee check-in and check-out" ),
        t( "Employee statuses" ),
        t( "Space-specific notifications" ),
        t( "Game rooms" ),
        t( "Report center" ),
        t( "Map building capability" ),
        t( "Location-based check-in and check-out for employees" ),
        t( "Automatic work plan tool after meeting recordings" ),
        t( "Manager's ability to share an employee's screen without their permission" ),
        t( "Whiteboard" )
    ];

    return (
        <>
            <Navbar bg="bg-white" text="text-black" logoFill="#333" btnbg={ primary } btntext="white" />
            <header className="relative mx-auto pt-40 pb-12 bg-black flex flex-col items-center justify-center border-b border-white">
                <div className="absolute inset-0">
                    <div className="absolute top-[22%] left-[18%] w-3 h-3">
                        <Star1 />
                    </div>
                    <div className="absolute top-[48%] left-[4%] w-3 h-3">
                        <Star2 />
                    </div>
                    <div className="absolute top-[54%] left-[8%] w-3 h-3">
                        <Star3 />
                    </div>
                    <div className="absolute top-[40%] right-[3.6%] w-3 h-3">
                        <Star4 />
                    </div>
                </div>
                <div className='relative z-[49] mx-auto max-w-[85rem] w-full px-12 sm:px-2 lm:px-2 flex justify-center flex-col items-center'>
                    <div className="pt-8">
                        <h6 className="text-center text-primary text-4.5xl font-semibold ltr:tracking-wider text-white">{t("Virtual Office Pricing")}</h6>
                        <h6 className="text-center text-xl font-medium leading-8 my-2 text-tertiary w-3/4 mx-auto sm:w-full lm:w-full">{t("Create a space where your remote team can cowork, hold meetings, and create a shared culture.")}</h6>
                    </div>

                    <div className='py-12 mb-2'>
                        <label className="inline-flex items-center cursor-pointer">
                            <span className="me-3 text-base sm:text-lg lm:text-lg font-medium text-tertiary">Monthly</span>
                            <input
                                type="checkbox"
                                value={ isAnnual ? 'annually' : 'monthly' }
                                className="sr-only peer"
                                onChange={ handleToggle }
                                checked={ isAnnual }
                            />
                            <div className="relative w-11 h-6 sm:h-[1.72rem] lm:h-[1.72rem] sm:w-[3rem] lm:w-[3rem] bg-transparent border-2 border-text1 peer-focus:outline-none rounded-full peer peer-checked:border-secondary peer-checked:after:translate-x-[1.2rem] rtl:peer-checked:after:-translate-x-full peer-checked:after:border-secondary peer-checked:after:bg-secondary after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-text1 after:border-text1 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-transparent"></div>
                            <span className="ms-3 text-base sm:text-lg lm:text-lg font-medium text-tertiary">Annually <span className='bg-text1 p-1 rounded-md'>-15%</span></span>
                        </label>
                    </div>

                    <div className='flex sm:flex-col lm:flex-col sm:w-full lm:w-full tab:w-full w-3/4 justify-between items-center px-12 sm:px-2 lm:px-8'>
                        <div className='w-1/2 sm:w-full lm:w-full bg-white px-6 py-7 rounded-3xl mx-5 h-115 sm:h-130vh lm:h-130vh shadow-shadowPricing sm:mt-6 lm:mt-6'>
                            <h6 className='font-semibold text-2xl'>{ t( "Basic" ) }</h6>
                            <h6 className='font-semibold text-4xl my-1.5'>{ basicPrice } { t( "SR" ) }</h6>
                            <p className='text-grey2 text-sm'>{ t( "Starts at 49 SR per month per user" ) }</p>
                            <div className='my-8'>
                                <Button width="100%" height="3rem" text={ t( "Get Started" ) } border={ `0 solid ${ primary }` } color={ primary } />
                            </div>
                            <ul className='pricing'>
                                { free.map( ( n, index ) => (
                                    <li className='text-sm sm:text-base lm:text-base font-medium my-4 ms-8 sm:ps-4 lm:ps-4 relative'>{ n }</li>
                                ) ) }
                            </ul>
                        </div>
                        <div className='w-1/2 sm:w-full lm:w-full bg-white px-6 py-7 rounded-3xl mx-5 h-115 sm:h-150vh lm:h-150vh shadow-shadowPricing sm:mt-6 lm:mt-6'>
                            <h6 className='font-semibold text-2xl'>{ t( "Advanced" ) }</h6>
                            <h6 className='font-semibold text-4xl my-1.5'>{ advancePrice } { t( "SR" ) }</h6>
                            <p className='text-grey2 text-sm'>{ t( "Starts at 69 SR per month per user" ) }</p>
                            <div className='my-8'>
                                <Button width="100%" height="3rem" text={ t( "Start Free 30-Day Trial" ) } border={ `0 solid ${ primary }` } color="white" background={ primary } />
                            </div>
                            <ul className='pricing'>
                                { premium.map( ( n, index ) => (
                                    <li className='text-sm sm:text-base lm:text-base font-medium my-4 ms-8 sm:ps-4 lm:ps-4 relative'>{ n }</li>
                                ) ) }
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
            <Footer />
        </>
    )
}