import React, { useState, useEffect, useMemo } from 'react';
import Navbar from "../Common/Navbar";
import Footer from '../Common/Footer';
import { primary } from "../Assets/Colors";
import Input from '../Components/Input';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import countryList from 'react-select-country-list'
import SelectInput from '../Components/Select';
import Textarea from '../Components/Textarea';
import { useTranslation } from "react-i18next";

export default function Contact ()
{
    const [ phone, setPhone ] = useState()
    const [ country, setCountry ] = useState( '' )
    const [ companySize, setCompanySize ] = useState( '' )
    const [ usage, setUsage ] = useState( '' )
    const [ agreePrivacyPolicy, setAgreePrivacyPolicy ] = useState( false );
    const [ receiveEmails, setReceiveEmails ] = useState( false );
    const countries = useMemo( () => countryList().getData(), [] )
    const { i18n, t } = useTranslation();
    const [ lang, setLang ] = useState( i18n.language );

    useEffect( () =>
    {
        const storedLang = localStorage.getItem( "lang" ) || "en";
        console.log( "Stored Language:", storedLang );
        i18n.changeLanguage( storedLang );
        setLang( storedLang );
        document.documentElement.dir = storedLang === "ar" ? "rtl" : "ltr";
        console.log( "Document Direction:", document.documentElement.dir );
    }, [ i18n, lang ] );

    const handlePrivacyPolicyChange = () =>
    {
        setAgreePrivacyPolicy( !agreePrivacyPolicy );
    };

    const handleReceiveEmailsChange = () =>
    {
        setReceiveEmails( !receiveEmails );
    };
    const checks = [
        {
            "policy": "<p>" + t( "I understand that information I submit through this form is governed by Space Twin's Privacy Policy." ) + "<span>*</span></p>",
            "agreement": "<p>" + t( "I agree to receive email marketing communications." ) + "</p>"
        }
    ];

    const compSize = [
        { value: '1 to 49 emplyees', label: t( '1 to 49 emplyees' ) },
        { value: '50 to 99 emplyees', label: t( '50 to 99 emplyees' ) },
        { value: '100 to 249 emplyees', label: t( '100 to 249 emplyees' ) },
        { value: '250 to 1000 emplyees', label: t( '250 to 1000 emplyees' ) },
        { value: '1000+ emplyees', label: t( '1000+ emplyees' ) },
    ];

    const uses = [
        { value: 'Virtual Office', label: t( 'Virtual Office' ) },
        { value: 'Team Activity or Social', label: t( 'Team Activity or Social' ) },
        { value: 'Conference', label: t( 'Conference' ) },
        { value: 'Classroom', label: t( 'Classroom' ) },
        { value: 'Community', label: t( 'Community' ) },
        { value: 'Other', label: t( 'Other' ) },
    ];

    const features = [
        t( "Feel like you're actually working together in a shared space to call your own." ),
        t( "Bring back casual conversations and talk naturally throughout your day." ),
        t( "Collaborate in the moment — don’t wait for meetings." )
    ];


    return (
        <div className="w-full">
            <Navbar bg="bg-white" text="text-black" logoFill="#333" btnbg={ primary } btntext="white" />
            <header className="mx-auto bg-black border-b border-white h-150vh sm:h-180vh lm:h-190vh tab:h-160vh sm:pb-12 lm:pb-12 sm:px-2 lm:px-2 tab:px-2">
                <div className="mx-auto max-w-[85rem] w-full h-full px-12 pt-44 flex sm:flex-col lm:flex-col sm:px-4 lm:px-4 relative tab:px-2 tab:pt-36">
                    <div className='w-1/2 sm:w-full lm:w-full flex flex-col text-white ps-4'>
                        <h6 className='text-5xl sm:text-center lm:text-center font-semibold'>{ t( "Contact our sales team" ) }</h6>
                        <p className='text-tertiary sm:text-center lm:text-center text-lg my-5'>{ t( "Let’s explore how a virtual office can help your team build a culture you love." ) }</p>
                        <ul className='features bg-darkPrimary w-[94%] rounded-2xl py-8 px-12 my-4'>
                            { features.map( ( n, index ) => (
                                <li className='text-base my-4 sm:ps-4 lm:ps-4'>{ n }</li>
                            ) ) }
                        </ul>
                        <h6 className='text-lg font-medium my-8'>{ t( "Need help with an existing office?" ) } <a href="#">{ t( "Visit our Help Center" ) }</a></h6>
                    </div>
                    <div className='w-1/2 sm:w-full lm:w-full'>
                        <form action="" className='bg-[#f2f2f2] rounded-xl w-[90%] sm:w-full lm:w-full mx-auto p-8'>
                            <div className='flex justify-between my-5 sm:flex-col lm:flex-col'>
                                <div className='w-[98%] sm:w-full lm:w-full sm:p-0 lm:p-0 pe-2'>
                                    <Input label={ t( "First Name" ) } type="text" placeholder="John" />
                                </div>
                                <div className='w-[98%] sm:w-full lm:w-full sm:p-0 lm:p-0 sm:mt-4 lm:mt-4 ps-2'>
                                    <Input label={ t( "Last Name" ) } type="text" placeholder="Doe" />
                                </div>
                            </div>
                            <div className='w-full my-5'>
                                <Input label={ t( "Work Email" ) } type="text" placeholder="hello@spacetwin.com" />
                            </div>
                            <div className="w-full my-5">
                                <label for="input-label" class="block text-sm sm:text-base lm:text-base mb-1">{ t( "Phone number" ) } ({ t( "optional" ) })</label>
                                <PhoneInput
                                    placeholder={ t( "Enter phone number" ) }
                                    phone={ phone }
                                    onChange={ setPhone } />
                            </div>
                            <div className='w-full my-5'>
                                <Input label={ t( "Company Name" ) } type="text" placeholder="Space Twin" />
                            </div>
                            <div className='w-full my-5'>
                                <Input label={ t( "No of Users" ) } type="number" placeholder="25" />
                            </div>
                            <div className='w-full my-5'>
                                <label for="input-label" class="block text-sm sm:text-base lm:text-base mb-1">{ t( "Company Size" ) }<span className="text-[red]">*</span></label>
                                <SelectInput options={ compSize } value={ companySize } placeholder={ t( "Please Select" ) } setValue={ ( value ) =>
                                {
                                    setCompanySize( value );
                                } } />
                            </div>
                            <div className='w-full my-5'>
                                <label for="input-label" class="block text-sm sm:text-base lm:text-base mb-1">{ t( "Country" ) }<span className="text-[red]">*</span></label>
                                <SelectInput options={ countries } value={ country } placeholder={ t( "Please Select" ) } setValue={ ( value ) =>
                                {
                                    setCountry( value );
                                } } />
                            </div>
                            <div className='w-full my-5'>
                                <label for="input-label" class="block text-sm sm:text-base lm:text-base mb-1">{ t( "What are you using Space Twin for?" ) }<span className="text-[red]">*</span></label>
                                <SelectInput options={ uses } value={ usage } placeholder={ t( "Please Select" ) } setValue={ ( value ) =>
                                {
                                    setUsage( value );
                                } } />
                            </div>
                            <div className='w-full my-5'>
                                <Textarea label={ t( "Anything else?" ) } placeholder={ t( "E.g. I am interested in SSO" ) } />
                            </div>

                            <div className="w-full my-5">
                                <label className="inline-flex items-center">
                                    <input
                                        type="checkbox"
                                        className="form-checkbox h-3 w-3 sm:h-8 lm:h-8 sm:w-8 lm:w-8 text-primary"
                                        checked={ agreePrivacyPolicy }
                                        onChange={ handlePrivacyPolicyChange }
                                    />
                                    <span className="ms-2 text-sm sm:text-base lm:text-base">
                                        <div
                                            className="text-gray-600"
                                            dangerouslySetInnerHTML={ { __html: checks[ 0 ].policy } }
                                        />
                                    </span>
                                </label>
                            </div>

                            <div className="w-full my-5">
                                <label className="inline-flex items-center">
                                    <input
                                        type="checkbox"
                                        className="form-checkbox h-3 w-3 sm:h-5 lm:h-7 sm:w-5 lm:w-7 text-primary"
                                        checked={ receiveEmails }
                                        onChange={ handleReceiveEmailsChange }
                                    />
                                    <span className="ms-2 text-sm sm:text-base lm:text-base">
                                        <div
                                            className="text-gray-600"
                                            dangerouslySetInnerHTML={ { __html: checks[ 0 ].agreement } }
                                        />
                                    </span>
                                </label>
                            </div>
                            <button className='bg-primary mb-3 text-sm sm:text-base lm:text-base text-white font-medium px-4 py-2.5 rounded-sm'>{ t( "Send message" ) }</button>
                        </form>
                    </div>
                </div>
            </header>
            <Footer />
        </div>
    )
}