import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEn from "../src/Assets/locales/en.json";
import translationAr from "../src/Assets/locales/ar.json";
const resources = {
    en: { translation: translationEn },
    ar: { translation: translationAr },
};

document.documentElement.dir = "ltr";
i18n.use( initReactI18next ).init( {
    resources,
    lng: "en",
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
} );
export default i18n;
