import Button from "./Button";
import { primary } from "../Assets/Colors";
import useWindowSize from '../Common/WindowSize';
import { useTranslation } from "react-i18next";

export default function TrySpaceTwin ( props )
{
    const size = useWindowSize();
    const { i18n, t } = useTranslation();

    return (
        <section className="w-full bg-white border-b border-[#d2d2d2] py-6">
            <div className="mx-auto max-w-[85rem] w-full px-12 py-16 sm:p-2 lm:p-2 flex flex-col items-center">
                <div className='w-3/4'>
                    <img src={ props.img } alt="image" width="130rem" className='mx-auto' />
                </div>
                <h6 className="text-center text-3xl font-semibold mx-auto mt-4 leading-normal ltr:tracking-wider w-2/3 sm:w-4/5 lm:w-4/5">{ props.text }</h6>
                <div className="flex flex-col items-center mt-10 mb-2 sm:w-full lm:w-full">
                    <Button onClick={ () => { window.location.href = "https://spacetwin.sa/"; } } text={t("Try Space Twin")}
                        width={ size.width > 300 && size.width < 531 ? "90%" : "9rem" }
                        height="3rem" fontWeight="700" background={ primary } color="white" />
                    <a href='/pricing' className='flex mt-6'>
                        <h6 className="text-base text-primary font-medium underline ml-5 mr-2 pt-0.5">{t("View pricing and plans")}</h6>
                    </a>
                </div>
            </div>
        </section>
    )
}