import combinator from "../Assets/svgs/Combination.svg"
import notion from "../Assets/svgs/Notion.svg"
import yelp from "../Assets/svgs/yelp.png"
import amplitude from "../Assets/svgs/Amplitude.svg"
import chain from "../Assets/svgs/Chainalysis.png"
import { useTranslation } from "react-i18next";

export default function Teams ()
{
    const { i18n, t } = useTranslation();

    return (
        <section className="relative flex items-center sm:items-start sm:pt-20 mx-auto bg-[#fbfbff] h-64 sm:h-100">
            <div className="relative z-[49] mx-auto max-w-[85rem] w-full px-12">
                <h6 className='font-medium mb-8 text-lg w-full text-center'>{t("Join the 1,000+ innovative teams using Space Twin")}</h6>
                <div className='h-14 flex sm:flex-wrap lm:flex-wrap sm:justify-center lm:justify-center items-center justify-around w-11/12 mx-auto'>
                    <div className='w-1/5 sm:w-1/2 lm:w-1/3 flex items-center justify-around '><img src={ combinator } alt="image" className='w-52 sm:w-80 h-8 sm:h-12' /></div>
                    <div className='w-1/5 sm:w-1/2 lm:w-1/3 flex items-center justify-around '><img src={ notion } alt="image" className='w-52 sm:w-80 h-8 sm:h-12' /></div>
                    <div className='w-1/5 sm:w-1/2 lm:w-1/3 flex items-center justify-around '><img src={ yelp } alt="image" className='w-32 sm:w-48 h-12 sm:h-14' /></div>
                    <div className='w-1/5 sm:w-1/2 lm:w-1/3 flex items-center justify-around '><img src={ amplitude } alt="image" className='w-52 sm:w-80 h-8 sm:h-12' /></div>
                    <div className='w-1/5 sm:w-1/2 lm:w-1/3 flex items-center justify-around '><img src={ chain } alt="image" className='w-44 sm:w-72 h-7 sm:h-10' /></div>
                </div>
            </div>
        </section>
    )
}