import person1 from "../Assets/images/person1.png"
import person2 from "../Assets/images/person2.png"
import { useTranslation } from "react-i18next";

export default function ReviewCard2 ()
{
    const { i18n, t } = useTranslation();

    return (
        <div className="w-full h-84 bg-white rounded-2xl flex flex-col items-center justify-center p-8">
            <div className="w-full text-base sm:text-lg lm:text-lg h-4/5 font-medium">
                “{t("There are nice perks that you don’t get in Google Meets. Space Twin is something different – it’s this atmosphere you can create that’s unique to your company that no one else has.")}”
            </div>
            <div className="w-full text-base h-1/5 font-medium flex">
                <div className="w-1/3 flex items-center justify-center relative">
                    <img src={ person1 } className="w-12 h-12 rounded-full absolute left-[40%]" alt="" />
                    <img src={ person2 } className="w-12 h-12 rounded-full absolute left-[10%]" alt="" />
                </div>
                <div className="w-2/3">
                    <div className="text-lg	font-bold">{t("Carolina Nucci")}</div>
                    <div className="text-base font-bold">{t("CMO at weme")}</div>
                </div>
            </div>
        </div>
    )
}